import http from "../http-common";

class Service {
    getMessages(channelId, skip, limit){
        return http.get(`/messages/messages/${channelId}/${skip}/${limit}`);
    }
    getAttachment(message){
        return http.get(`/messages/attachment/${message.channel}/${message._id}`,
            {responseType: 'blob'});
    }
    getNumberOfUnreadMessages(channelId){
        return http.get(`/messages/numberOfUnreadMessages/${channelId}`);
    }
    post(channelId, content, supervision, expectSupervision, attachmentFile, contextInfo){
        const formData = new FormData();
        formData.append('content', content);
        formData.append('supervision', supervision);
        formData.append('expectSupervision', expectSupervision);
        formData.append('attachment', attachmentFile);
        formData.append('contextInfo', JSON.stringify(contextInfo));
        return http.post(`/messages/${channelId}`, formData);
    }
    setEmpty(message){
        return http.put(`/messages/empty/${message.channel}/${message._id}`);
    }
}

export const MessageService = new Service();

